<script lang="ts">
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        /**
         * If the URL has a token query param, it means that we should show the
         * "enter new password" screen, else we should ask the user for their email
         * to send the password reset link.
         */
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        return {
            // the email property is used for the enter email screen
            email: "",
            error: false,
            errorMessage: "Something Went Wrong",
            didSubmit: false,
            // if tokenPresent is true, it means that the user has clicked on the
            // reset password link.
            tokenPresent: token !== null,
            password: "",
        };
    },
    methods: {
        onSubmitClicked: async function () {
            if (this.tokenPresent) {
                // we try and change the user's password now by consuming the token
                try {
                    const response = await ThirdPartyEmailPassword.submitNewPassword({
                        formFields: [
                            {
                                id: "password",
                                value: this.password,
                            },
                        ],
                    });

                    if (response.status === "FIELD_ERROR") {
                        // this indicates that the password entered by the user
                        // did not match the backend password validation logic.
                        throw new Error(response.formFields[0].error);
                    } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
                        // the password reset token was consumed already, or has expired.
                        // in this case, the user should go back to the login screen or the
                        // enter email screen
                        throw new Error("Password reset token has expired, please go back to the sign in page");
                    }

                    // password reset successful.
                    window.location.assign("/auth");
                } catch (e: any) {
                    this.errorMessage = e.message;
                    this.error = true;
                }
            } else {
                // the user has entered an email for whom the password reset link
                // will be sent.
                try {
                    const response = await ThirdPartyEmailPassword.sendPasswordResetEmail({
                        formFields: [
                            {
                                id: "email",
                                value: this.email,
                            },
                        ],
                    });

                    if (response.status !== "OK") {
                        // this means that the email validation logic failed.
                        throw new Error(response.formFields[0].error);
                    }

                    // a password reset email was sent successfully.

                    if (this.didSubmit !== true) {
                        // we change the UI to show that the email has been sent
                        this.didSubmit = true;
                    }
                } catch (e: any) {
                    this.errorMessage = e.message;
                    this.error = true;
                }
            }
        },
    },
});
</script>

<template>
    <div class="fill">
    <div class="form-container">
        <div v-if="tokenPresent" class="form-content-container">
            <div v-if="error" class="top-error-container">
                <div class="error-message">{{ errorMessage }}</div>
            </div>
            <div v-if="!didSubmit">
                <div class="form-text-header">Enter new password</div>
                <div class="form-subtitle">Please enter your new password below</div>

                <div class="input-section-container">
                    <div class="input-label">New Password</div>
                    <div class="input-container">
                        <div class="input-wrapper">
                            <input
                                autocomplete="current-password"
                                class="input"
                                type="password"
                                name="password"
                                placeholder="Password"
                                v-model="password"
                            />
                        </div>
                    </div>
                </div>

                <div class="button-container">
                    <button v-on:click="onSubmitClicked" class="form-button">Change Password</button>
                </div>
            </div>
        </div>
        <div v-else class="form-content-container">
            <div v-if="error" class="top-error-container">
                <div class="error-message">{{ errorMessage }}</div>
            </div>
            <div v-if="!didSubmit">
                <div class="form-text-header">Reset your password</div>
                <div class="form-subtitle">We will send you an email to reset your password</div>

                <div class="input-section-container">
                    <div class="input-label">Email</div>
                    <div class="input-container">
                        <div class="input-wrapper">
                            <input autocomplete="email" class="input" type="email" name="email" v-model="email" />
                        </div>
                    </div>
                </div>

                <div class="button-container">
                    <button v-on:click="onSubmitClicked" class="form-button">Email Me</button>
                </div>
            </div>
            <div v-else>
                <div class="confirmation">
                    Please check your email for the password recovery link
                    <span class="resend-button" v-on:click="onSubmitClicked">Resend</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
@import "@/assets/base.css";
@import "./css/forgotpassword.css";
</style>
