<script lang="ts">
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import Session from "supertokens-web-js/recipe/session";
import { defineComponent } from "vue";
import { apiDomain } from "../../main";

const websitePort = import.meta.env.VITE_VUE_APP_WEB_PORT || 3000;
const websiteDomain = import.meta.env.VITE_VUE_APP_WEB_URL || `http://localhost:${websitePort}`;

export default defineComponent({
    data() {
        return {
            // we allow the user to switch between sign in and sign up view
            isSignIn: true,

            // this will store the email and password entered by the user.
            email: "",
            password: "",

            // any generic error states
            error: false,
            errorMessage: "Something went wrong",

            // any error states specific to the input fields.
            emailError: "",
            passwordError: "",
        };
    },

    mounted() {
        // if there is an "error" query param on this page, it means that
        // social login has failed for some reason. See the AuthCallbackView.vue file
        // for more context on this
        const params = new URLSearchParams(window.location.search);

        if (params.has("error")) {
            this.errorMessage = "Something went wrong";
            this.error = true;
        }

        // this redirects the user to the HomeView.vue component if a session
        // already exists.
        this.checkForSession();
    },

    methods: {
        goToSignUp() {
            this.isSignIn = false;
        },
        goToSignIn() {
            this.isSignIn = true;
        },
        signIn: async function (_: Event) {
            const response = await ThirdPartyEmailPassword.emailPasswordSignIn({
                formFields: [
                    {
                        id: "email",
                        value: this.email,
                    },
                    {
                        id: "password",
                        value: this.password,
                    },
                ],
            });

            if (response.status === "WRONG_CREDENTIALS_ERROR") {
                // the input email / password combination did not match,
                // so we show an appropriate error message to the user
                this.errorMessage = "Invalid credentials";
                this.error = true;
                return;
            }

            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach((item) => {
                    if (item.id === "email") {
                        // this means that something was wrong with the entered email.
                        // probably that it's not a valid email (from a syntax point of view)
                        this.emailError = item.error;
                    } else if (item.id === "password") {
                        this.passwordError = item.error;
                    }
                });
                return;
            }

            // login is successful, and we redirect the user to the home page.
            // Note that session cookies are added automatically and nothing needs to be
            // done here about them.
            window.location.assign("/");
        },
        validateEmail(email: string) {
            return email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        signUp: async function (_: Event) {
            const response = await ThirdPartyEmailPassword.emailPasswordSignUp({
                formFields: [
                    {
                        id: "email",
                        value: this.email,
                    },
                    {
                        id: "password",
                        value: this.password,
                    },
                ],
            });

            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach((item) => {
                    if (item.id === "email") {
                        // this means that something was wrong with the entered email.
                        // probably that it's not a valid email (from a syntax point of view)
                        this.emailError = item.error;
                    } else if (item.id === "password") {
                        // this means that something was wrong with the entered password.
                        // probably it doesn't meet the password validation criteria on the backend.
                        this.passwordError = item.error;
                    }
                });
                return;
            }

            // signup is successful, and we redirect the user to the home page.
            // Note that session cookies are added automatically and nothing needs to be
            // done here about them.
            window.location.assign("/");
        },
        onSubmitPressed: function (e: Event) {
            e.preventDefault();
            // we reset the error states in case the user has fixed the input errors
            this.error = false;
            this.emailError = "";
            this.passwordError = "";

            if (this.isSignIn) {
                this.signIn(e);
            } else {
                this.signUp(e);
            }
        },
        onGithubPressed: async function () {
            const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "github",

                // This is where github should redirect the user back after login or error.
                // This URL goes on the github dashboard as well.
                frontendRedirectURI: `${websiteDomain}/auth/callback/github`,
            });

            window.location.assign(authUrl);
        },
        onGooglePressed: async function () {
            const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "google",

                // This is where google should redirect the user back after login or error.
                // This URL goes on the google dashboard as well.
                frontendRedirectURI: `${websiteDomain}/auth/callback/google`,
            });

            window.location.assign(authUrl);
        },
        onApplePressed: async function () {
            const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
                thirdPartyId: "apple",

                // This is where apple should redirect the user back after login or error.
                // This URL goes on the apple dashboard as well.
                frontendRedirectURI: `${websiteDomain}/auth/callback/apple`,
                redirectURIOnProviderDashboard: `${apiDomain}/auth/callback/apple`,
            });

            window.location.assign(authUrl);
        },
        checkForSession: async function () {
            if (await Session.doesSessionExist()) {
                // since a session already exists, we redirect the user to the HomeView.vue component
                window.location.assign("/");
            }
        },
    },
});
</script>

<template>
    <div class="auth-container">
    <div class="auth-form-container">
        <div v-if="error" class="error-container">
            <div class="error-message">{{ errorMessage }}</div>
        </div>
        <div class="auth-form-content-container">
            <div class="form-title" v-if="isSignIn">Sign In</div>
            <div class="form-title" v-else>Sign Up</div>
            <div class="sign-in-up-text-container">
                <span v-if="isSignIn"
                    >Not yet registered? <span class="clickable-text" v-on:click="goToSignUp">Sign Up</span></span
                >
                <span v-else
                    >Already have an account? <span class="clickable-text" v-on:click="goToSignIn">Sign In</span></span
                >
            </div>
            <div class="divider-container">
                <div class="divider" />
            </div>
            <!-- <div class="providerContainer">
                <span>
                    <button class="providerButton providerGithub" @click="onGithubPressed">
                        <div class="providerButtonLeft">
                            <div class="providerButtonLogo">
                                <div class="providerButtonLogoCenter">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="17.556"
                                        viewBox="0 0 18 17.556"
                                    >
                                        <path
                                            fill="#fff"
                                            fill-rule="evenodd"
                                            d="M145.319 107.44a9 9 0 0 0-2.844 17.54c.45.082.614-.2.614-.434 0-.214-.008-.78-.012-1.531-2.5.544-3.032-1.206-3.032-1.206a2.384 2.384 0 0 0-1-1.317c-.817-.559.062-.547.062-.547a1.89 1.89 0 0 1 1.378.927 1.916 1.916 0 0 0 2.619.748 1.924 1.924 0 0 1 .571-1.2c-2-.227-4.1-1-4.1-4.448a3.479 3.479 0 0 1 .927-2.415 3.233 3.233 0 0 1 .088-2.382s.755-.242 2.475.923a8.535 8.535 0 0 1 4.506 0c1.718-1.165 2.472-.923 2.472-.923a3.234 3.234 0 0 1 .09 2.382 3.473 3.473 0 0 1 .925 2.415c0 3.458-2.1 4.218-4.11 4.441a2.149 2.149 0 0 1 .611 1.667c0 1.2-.011 2.174-.011 2.469 0 .24.162.52.619.433a9 9 0 0 0-2.851-17.539z"
                                            transform="translate(-136.32 -107.44)"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="providerButtonText">Continue with Github</div>
                    </button>
                </span>
            </div> -->

            <div class="providerContainer">
                <span>
                    <button class="providerButton providerGoogle" @click="onGooglePressed">
                        <div class="providerButtonLeft">
                            <div class="providerButtonLogo">
                                <div class="providerButtonLogoCenter">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.001"
                                        height="18"
                                        viewBox="0 0 18.001 18"
                                    >
                                        <g id="Group_9292" transform="translate(-534 -389)">
                                            <path
                                                id="Path_85803"
                                                d="M3.989 144.285l-.627 2.339-2.29.048a9.016 9.016 0 0 1-.066-8.4l2.039.374.893 2.027a5.371 5.371 0 0 0 .05 3.616z"
                                                transform="translate(534 255.593)"
                                                style="fill: rgb(255, 255, 255)"
                                            ></path>
                                            <path
                                                id="Path_85804"
                                                d="M270.273 208.176a9 9 0 0 1-3.208 8.7l-2.568-.131-.363-2.269a5.364 5.364 0 0 0 2.308-2.739h-4.813v-3.56h8.645z"
                                                transform="translate(281.57 188.143)"
                                                style="fill: rgb(255, 255, 255)"
                                            ></path>
                                            <path
                                                id="Path_85805"
                                                d="M44.07 314.549a9 9 0 0 1-13.561-2.749l2.917-2.387a5.353 5.353 0 0 0 7.713 2.741z"
                                                transform="translate(504.564 90.469)"
                                                style="fill: rgb(255, 255, 255)"
                                            ></path>
                                            <path
                                                id="Path_85806"
                                                d="M42.362 2.072l-2.915 2.387a5.352 5.352 0 0 0-7.89 2.8l-2.932-2.4a9 9 0 0 1 13.737-2.787z"
                                                transform="translate(506.383 389)"
                                                style="fill: rgb(255, 255, 255)"
                                            ></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="providerButtonText">Continue with Google</div>
                    </button>
                </span>
            </div>

            <div class="divider-container">
                <div class="divider" />
                <div class="divider-text">or</div>
                <div class="divider" />
            </div>

            <form v-on:submit="onSubmitPressed" autocomplete="on" novalidate>
                <div class="input-section-container" v-bind:class="emailError ? 'error' : ''">
                    <div class="input-label">Email</div>
                    <div class="input-container">
                        <div class="input-wrapper" v-bind:class="emailError ? 'error' : ''">
                            <input
                                autocomplete="email"
                                class="input"
                                type="email"
                                name="email"
                                placeholder="Email address"
                                v-model="email"
                            />
                        </div>
                    </div>
                    <div v-if="emailError" class="input-error">{{ `${emailError}` }}</div>
                </div>

                <div class="input-section-container" v-bind:class="passwordError ? 'error' : ''">
                    <div class="input-label">Password</div>
                    <div class="input-container">
                        <div class="input-wrapper" v-bind:class="passwordError ? 'error' : ''">
                            <input
                                autocomplete="current-password"
                                class="input"
                                type="password"
                                name="password"
                                placeholder="Password"
                                v-model="password"
                            />
                        </div>
                    </div>
                    <div v-if="passwordError" class="input-error">{{ `${passwordError}` }}</div>
                </div>

                <div class="input-section-container">
                    <div v-if="isSignIn">
                        <button type="submit" class="button">SIGN IN</button>
                    </div>
                    <div v-else>
                        <button type="submit" class="button">SIGN UP</button>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="isSignIn">
            <router-link :to="{ path: `/auth/reset-password` }"> Forgot Password? </router-link>
        </div>
        <div style="margin-bottom: 10px" />
    </div>
</div>

</template>

<style>
@import "@/assets/base.css";
@import "./css/authview.css";
</style>
