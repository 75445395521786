import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/auth/HomeView.vue";
import AuthView from "../views/auth/AuthView.vue";
import AuthCallbackView from "../views/auth/AuthCallbackView.vue";
import ForgotPasswordView from "../views/auth/ForgotPassword.vue";


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/auth",
            name: "auth",
            component: AuthView,
        },
        {
            path: "/auth/callback/:provider",
            name: "authcallback",
            component: AuthCallbackView,
        },
        {
            path: "/auth/reset-password",
            name: "resetPassword",
            component: ForgotPasswordView,
        },
    ],
});

export default router;
