/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'


// Composables
import { createApp } from 'vue'
import SuperTokens from "supertokens-web-js"
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import Session from "supertokens-web-js/recipe/session";
import router from './router';


// Plugins
import { registerPlugins } from '@/plugins'

const apiPort = import.meta.env.VITE_VUE_APP_API_PORT || 3001;
export const apiDomain = import.meta.env.VITE_VUE_APP_API_URL || `http://localhost:${apiPort}`;

SuperTokens.init({
    appInfo: {
        appName: "Kosmiq",
        apiDomain,
    },
    recipeList: [ThirdPartyEmailPassword.init({
    }), Session.init()],
});


const app = createApp(App)
app.use(router)

registerPlugins(app)

app.mount('#app')
